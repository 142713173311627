import { useCallback, useEffect, useRef, useState } from 'react';
import { ViewerControl } from '@prizmdoc/viewer-core';
import { getScrollContainer } from '../utils';
import { MARK_TYPE, PRESENT_COLOR } from '../constants';
import { MouseToolType } from '../../types';

export const usePauseDocument = (
  viewerControlRef: React.MutableRefObject<typeof ViewerControl | null>,
  isTheatreMode: boolean,
  initializingRef: React.MutableRefObject<number | null>,
  selectedMouseTool: any,
) => {
  const [isDisconnected, setIsDisconnected] = useState<boolean | undefined>(undefined);
  const [pauseTheatreMode, setPauseTheatreMode] = useState(false);

  const pauseTheatreModeTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const scrollContainer = getScrollContainer(viewerControlRef.current);

  const disconnectPauseTheatreModeHandler = () => {
    clearPauseTheatreModeTimeout();
    setIsDisconnected(true);
  };

  const unpauseTheatreMode = useCallback(() => {
    setPauseTheatreMode(prev => (prev ? false : prev));
    isDisconnected && setIsDisconnected(false);
  }, [isDisconnected]);

  const clearPauseTheatreModeTimeout = () => {
    pauseTheatreModeTimeoutRef.current && clearTimeout(pauseTheatreModeTimeoutRef.current);
  };

  const pauseTheatreModeHandler = useCallback(() => {
    if (isTheatreMode && !isDisconnected && !initializingRef.current) {
      setPauseTheatreMode(prev => (!prev ? true : prev));
      clearPauseTheatreModeTimeout();
      pauseTheatreModeTimeoutRef.current = setTimeout(() => {
        unpauseTheatreMode();
      }, 3000);
    }
  }, [isDisconnected, isTheatreMode, unpauseTheatreMode, initializingRef]);

  const unpauseTheatreModeHandler = () => {
    setPauseTheatreMode(prev => (prev ? false : prev));
    isDisconnected && setIsDisconnected(false);
  };

  // MOUNT
  useEffect(() => {
    return () => {
      pauseTheatreModeTimeoutRef.current && clearTimeout(pauseTheatreModeTimeoutRef.current);
    };
  }, []);

  useEffect(() => {
    if (!viewerControlRef.current) return;

    const mouseUpDownHandler = () => {
      if (selectedMouseTool !== MouseToolType.PanAndEdit) {
        pauseTheatreModeHandler();
      }
    };

    const markCreatedHandler = ({ mark }: any) => {
      const isScrollMark = mark.type === MARK_TYPE;
      const isPresentMark = mark.fillColor === PRESENT_COLOR;
      // if it's not scroll mark and not presentationmark, we need to pause (user is creating mark)
      if (isTheatreMode && !isScrollMark && !isPresentMark) {
        pauseTheatreModeHandler();
      }
    };

    const documentRotatedHandler = () => {
      pauseTheatreModeHandler();
    };

    const documentScrolledHandler = () => {
      pauseTheatreModeHandler();
    };

    const documentPageChangedHandler = () => {
      pauseTheatreModeHandler();
    };

    const documentScaleChangedHandler = () => {
      pauseTheatreModeHandler();
    };

    if (isTheatreMode) {
      // events that bring viewer in pause mode
      viewerControlRef.current?.on('DocumentRotated', documentRotatedHandler);
      scrollContainer?.addEventListener('scroll', documentScrolledHandler);
      scrollContainer?.addEventListener('mouseup', mouseUpDownHandler);
      scrollContainer?.addEventListener('mousedown', mouseUpDownHandler);
      viewerControlRef.current?.on('MarkCreated', markCreatedHandler);
      viewerControlRef.current?.on('PageChanged', documentPageChangedHandler);
      viewerControlRef.current?.on('ScaleChanged', documentScaleChangedHandler);
    } else {
      // TO-DO
      // when page or scale changes we need to update customToolbox
      // viewerControl.on('PageChanged', invalidate);
      // viewerControl.on('ScaleChanged', invalidate);
    }

    const vr = viewerControlRef.current;

    return () => {
      if (isTheatreMode) {
        vr?.off('DocumentRotated', documentRotatedHandler);
        scrollContainer?.removeEventListener('scroll', documentScrolledHandler);
        scrollContainer?.removeEventListener('mouseup', mouseUpDownHandler);
        scrollContainer?.removeEventListener('mousedown', mouseUpDownHandler);
        vr?.off('MarkCreated', markCreatedHandler);
        vr?.off('PageChanged', documentPageChangedHandler);
        vr?.off('ScaleChanged', documentScaleChangedHandler);
      } else {
        // viewerControl.off('PageChanged', invalidate);
        // viewerControl.off('ScaleChanged', invalidate);
      }
    };
  }, [
    isTheatreMode,
    pauseTheatreModeHandler,
    scrollContainer,
    selectedMouseTool,
    viewerControlRef,
  ]);

  return {
    unpauseTheatreModeHandler,
    disconnectPauseTheatreModeHandler,
    pauseTheatreMode,
  };
};
