/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  keyframes,
  Button,
  Autocomplete,
  TextField,
  IconButton,
  Tooltip,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import T from 'i18n';
import { useSelector } from 'react-redux';
import { selectCurrentCaseId, selectFolders } from 'common/selectors';
import { useFetchAnnotations } from 'features/viewing/redux/fetchAnnotations';
import api from 'common/api';
import { ExistingHyperlinkRow } from '../../extraComponents/hyperlinks/ExistingHyperLinkRow';
import { Spinner } from 'features/common';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import RefreshIcon from '@mui/icons-material/Refresh';
import CloseIcon from '@mui/icons-material/Close';
import { flattenFolders, removeIndexFolders } from 'utils/foldersStructuring';
import { CheckCircle, FilterListRounded } from '@mui/icons-material';

const underlineAnimation = keyframes`
  0% {
    text-decoration: underline;
  }
  75% {
    text-decoration: underline;
  }
  100% {
    text-decoration: none;
  }
`;

const useStyles = makeStyles({
  tableContainer: {
    maxHeight: '400px',
    overflowY: 'auto',
    overflowX: 'auto',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  th: {
    borderBottom: '1px solid #ccc',
    textAlign: 'left',
    padding: '8px',
    position: 'sticky',
    top: 0,
    backgroundColor: '#f9f9f9',
    zIndex: 1,
  },
  td: {
    borderBottom: '1px solid #eee',
    padding: '8px',
  },
  existingTitle: {
    animation: `${underlineAnimation} 5s ease-in-out`,
  },
});

type ManageHyperlinksProps = {
  show: boolean;
  handleClose: () => void;
  selectedRows: any[];
};

export const ManageHyperlinks: React.FC<ManageHyperlinksProps> = ({
  show,
  handleClose,
  selectedRows,
}) => {
  const classes = useStyles();

  const caseId = useSelector(selectCurrentCaseId);
  const folders = useSelector(selectFolders);

  const file: any = selectedRows?.[selectedRows.length - 1];

  const [loading, setLoading] = useState(true);
  const [fetchError, setFetchError] = useState<string | null>(null);
  const [existingHyperlinks, setExistingHyperlinks] = useState<any[]>([]);
  const [selectedFolder, setSelectedFolder] = useState('');
  const [selectedFolderId, setSelectedFolderId] = useState('');
  const [assignmentComplete, setAssignmentComplete] = useState(false);

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const [filterValue, setFilterValue] = useState('');

  const { fetchAnnotations } = useFetchAnnotations();

  const trialBundlesWithoutIndexFolder = removeIndexFolders(folders['trialbooks']);
  const trialBundlesMenu = flattenFolders(trialBundlesWithoutIndexFolder);
  const teamBundlesMenu = flattenFolders(folders['teamBundles']);
  const privateBundlesMenu = flattenFolders(folders['privateBundles']);

  const foldersList = [
    trialBundlesMenu?.length && { id: 'trialBundle', name: 'Trial Bundles' },
    ...trialBundlesMenu,
    teamBundlesMenu?.length && { id: 'teamBundles', name: 'Team Bundles' },
    ...teamBundlesMenu,
    privateBundlesMenu?.length && { id: 'privateBundles', name: 'Private Bundles' },
    ...privateBundlesMenu,
  ]
    .filter(Boolean)
    .map((f: any) => (typeof f === 'object' ? f : {}));

  useEffect(() => {
    if (!show || !file) return;
    getExistingHyperlinks();
  }, [show, caseId, file]);

  const getExistingHyperlinks = async () => {
    try {
      setLoading(true);
      let existing = await fetchAnnotations({ file: file.id });
      existing = existing.filter((ann: any) => ann.annotation.type === 'TextHyperlinkAnnotation');

      existing.sort((a: any, b: any) => {
        return a?.annotation?.pageNumber - b?.annotation?.pageNumber;
      });

      setExistingHyperlinks(existing);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error instanceof Error) {
        setFetchError(error.message);
      } else {
        setFetchError('Unknown error occurred');
      }
    }
  };

  const getFilteredHyperlinks = () => {
    if (!filterValue.trim()) {
      return existingHyperlinks;
    }
    const filter = filterValue.toLowerCase();

    return existingHyperlinks.filter(ann => {
      const docId = (
        ann.annotation?.targetDocId ||
        ann.annotation?.data?.linkId ||
        ''
      ).toLowerCase();
      const targetFile = (ann.annotation?.targetFilename || '').toLowerCase();
      const sourceText = (ann.annotation?.text || '').toLowerCase(); // If you store user text
      const createdByName = (ann.createdBy?.name || ann.createdBy?.id || '').toLowerCase();
      const pageNumber = ann.annotation?.pageNumber?.toString() || '';

      return (
        docId.includes(filter) ||
        targetFile.includes(filter) ||
        sourceText.includes(filter) ||
        createdByName.includes(filter) ||
        pageNumber.includes(filter)
      );
    });
  };

  const clearAll = () => {
    setLoading(false);
    setFetchError(null);
    setExistingHyperlinks([]);
    setSelectedFolder('');
    setAssignmentComplete(false);
    setShowDeleteConfirmation(false);

    setFilterValue('');
  };

  const onClose = () => {
    clearAll();
    handleClose();
  };

  const handleDeleteAll = async () => {
    try {
      setLoading(true);
      await api.del(`/cases/${caseId}/files/${file.id}/hyperlinks`);
      handleClose();
    } catch (err) {
      setLoading(false);
      if (err instanceof Error) {
        setFetchError(err.message);
      } else {
        setFetchError('Unknown error occurred');
      }
    }
  };
  const handleDeleteIconClick = () => {
    setShowDeleteConfirmation(true);
    setTimeout(() => setShowDeleteConfirmation(false), 5000);
  };
  const handleCancelDelete = () => {
    setShowDeleteConfirmation(false);
  };

  const handleRefresh = () => {
    clearAll();
    getExistingHyperlinks();
  };

  const handleAssignFiles = async () => {
    const docs = existingHyperlinks.map((ann: any) => ({
      id: ann?.annotation?.targetDocId || ann?.annotation?.data?.linkId,
      name: ann?.annotation?.targetFilename || ann?.annotation?.data?.linkName,
    }));

    const seen = new Set<string>();
    const uniqueDocs = docs.filter(doc => {
      if (!doc.id) return false;
      if (seen.has(doc.id)) {
        return false;
      }
      seen.add(doc.id);
      return true;
    });

    if (!uniqueDocs.length) {
      console.warn('No doc IDs found in existingHyperlinks.');
      return;
    }
    if (!selectedFolder) {
      console.warn('No folder selected.');
      return;
    }

    try {
      await api.post(
        `/cases/${caseId}/folders/${selectedFolderId}/files?includeFileList=false`,
        uniqueDocs,
      );
      setAssignmentComplete(true);
    } catch (error) {
      console.error('Error assigning hyperlinks:', error);
    }
  };

  const handleAssignAgain = () => {
    setAssignmentComplete(false);
    setSelectedFolder('');
    setSelectedFolderId('');
  };

  const displayedHyperlinks = getFilteredHyperlinks();

  const valKey = 'name';
  const labelKey = 'name';

  return (
    <Dialog open={show} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        {T.translate('hyperlinks.preview.title', {
          defaultValue: 'Manage Existing Hyperlinks',
        })}
        <IconButton size="small" onClick={onClose} sx={{ ml: 2 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent className={classes.tableContainer}>
        <Box
          display="flex"
          alignItems="center"
          gap={2}
          mb={2}
          sx={{
            borderBottom: '1px solid #ccc',
            pb: 1,
            justifyContent: 'space-between',
          }}
        >
          <Box display="flex" alignItems="center" gap={2}>
            <FolderCopyIcon fontSize="small" sx={{ mt: '22px', color: 'gray' }} />
            <Autocomplete
              size="medium"
              sx={{ minWidth: 200 }}
              options={foldersList || []}
              getOptionLabel={(option: any) => (option[labelKey] ? option[labelKey] : '')}
              noOptionsText={T.translate('generic.noOptions', { defaultValue: 'No options' })}
              value={foldersList.find((val: any) => val[valKey] === selectedFolder) || null}
              onChange={(e, value: any) => {
                if (value && value[valKey]) {
                  setSelectedFolder(value[valKey]);
                  setSelectedFolderId(value.id);
                } else {
                  setSelectedFolder('');
                }
              }}
              getOptionDisabled={option =>
                option['id'] === 'trialBundle' ||
                option['id'] === 'teamBundles' ||
                option['id'] === 'privateBundles' ||
                (option.folders && option.folders.length > 0)
              }
              inputValue={selectedFolder}
              onInputChange={(event, value, reason) => {
                if (reason !== 'reset') {
                  setSelectedFolder(value);
                }
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  variant="standard"
                  label={T.translate('hyperlinks.assign.tooltip', {
                    defaultValue: 'Assign Hyperlinks to Bundle',
                  })}
                />
              )}
            />
            {!assignmentComplete && (
              <Tooltip
                title={T.translate('hyperlinks.assign.tooltip', {
                  defaultValue: 'Assign Existing Hyperlinks to another folder',
                })}
              >
                <span>
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={handleAssignFiles}
                    disabled={!selectedFolder || !existingHyperlinks.length || loading}
                    sx={{ mt: '22px' }}
                  >
                    {T.translate('hyperlinks.assign.button', { defaultValue: 'Assign' })}
                  </Button>
                </span>
              </Tooltip>
            )}
            {assignmentComplete && (
              <Box display="flex" gap={1} alignItems="center" sx={{ mt: '22px' }}>
                <CheckCircle color="success" />
                <Typography variant="body2">
                  {T.translate('hyperlinks.copyTo', { defaultValue: 'Copy to' })}
                  <strong>{selectedFolder}</strong>
                </Typography>
                <Button size="small" variant="outlined" onClick={handleAssignAgain}>
                  {T.translate('hyperlinks.assignAgain', { defaultValue: 'Assign Again' })}
                </Button>
              </Box>
            )}
          </Box>

          <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
            <Box display="flex" flexDirection="row" alignContent="center">
              <TextField
                variant="standard"
                placeholder="Filter"
                value={filterValue}
                onChange={e => setFilterValue(e.target.value)}
                sx={{ minWidth: 140, borderColor: 'gray' }}
                InputProps={{
                  endAdornment: filterValue && (
                    <Tooltip title="Clear Filter">
                      <IconButton
                        onClick={() => setFilterValue('')}
                        edge="end"
                        size="small"
                        sx={{ color: 'gray' }}
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  ),
                }}
              />
              <FilterListRounded sx={{ color: 'gray', mr: 1 }} />
            </Box>
            {showDeleteConfirmation && (
              <Box display="flex" gap={1} alignItems="center">
                <Tooltip title={T.translate('generic.confirm', { defaultValue: 'Confirm Delete' })}>
                  <IconButton
                    onClick={handleDeleteAll}
                    disabled={loading}
                    color="error"
                    size="small"
                  >
                    <CheckCircle fontSize="small" />
                  </IconButton>
                </Tooltip>
                <Tooltip title={T.translate('generic.cancel', { defaultValue: 'Cancel Delete' })}>
                  <IconButton onClick={handleCancelDelete} disabled={loading} size="small">
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
            <Tooltip
              title={T.translate('hyperlinks.deleteAll.tooltip', {
                defaultValue: 'Delete All My Hyperlinks',
              })}
            >
              <span>
                <IconButton
                  onClick={handleDeleteIconClick}
                  disabled={loading || existingHyperlinks.length === 0}
                  color="error"
                  size="small"
                >
                  <DeleteForeverIcon fontSize="small" />
                </IconButton>
              </span>
            </Tooltip>

            <Tooltip
              title={T.translate('hyperlinks.refresh.tooltip', { defaultValue: 'Refresh List' })}
            >
              <span>
                <IconButton onClick={handleRefresh} disabled={loading} size="small">
                  <RefreshIcon fontSize="small" />
                </IconButton>
              </span>
            </Tooltip>
          </Box>
        </Box>

        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" my={3}>
            <Spinner />
          </Box>
        ) : fetchError ? (
          <Typography variant="body1" color="error">
            {fetchError}
          </Typography>
        ) : displayedHyperlinks.length === 0 ? (
          <Typography variant="body2" color="textSecondary" mt={2}>
            {T.translate('hyperlinks.preview.noData', {
              defaultValue: 'No hyperlink candidates.',
            })}
          </Typography>
        ) : (
          <Box mt={2}>
            <table className={classes.table}>
              <thead>
                <tr>
                  <th className={classes.th}>
                    {T.translate('hyperlinks.preview.sourcePageHeader', {
                      defaultValue: 'Source Page',
                    })}
                  </th>
                  <th className={classes.th}>
                    {T.translate('hyperlinks.preview.sourceTextHeader', {
                      defaultValue: 'Source Text',
                    })}
                  </th>
                  <th className={classes.th}>
                    {T.translate('hyperlinks.preview.docIdHeader', {
                      defaultValue: 'Target Doc Id.',
                    })}
                  </th>
                  <th className={classes.th}>
                    {T.translate('hyperlinks.preview.createdBy', {
                      defaultValue: 'Created By',
                    })}
                  </th>
                  <th className={classes.th}>
                    {T.translate('hyperlinks.preview.linkPageHeader', {
                      defaultValue: 'Target FileName',
                    })}
                  </th>
                </tr>
              </thead>
              <tbody>
                {displayedHyperlinks.map((ann, idx) => (
                  <ExistingHyperlinkRow key={idx} ann={ann} classes={classes} />
                ))}
              </tbody>
            </table>
          </Box>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} sx={{ color: '#748CEC' }}>
          {T.translate('hyperlinks.preview.cancel', {
            defaultValue: 'Close',
          })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ManageHyperlinks;
