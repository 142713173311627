import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import qs from 'qs';
import { getAlpha } from 'utils/color';
import {
  selectCurrentCase,
  selectCurrentFolder,
  selectCurrentSelectedFileMetaData,
  selectIsGlobalPageLookup,
  selectIsLiveStream,
  selectMatchedUrl,
  selectPermissions,
  selectQuery,
  selectTheatreData,
  selectUserId,
  selectSessionStarted,
  selectFilteredDocumentAnnotations,
  selectMarkBasedScrolling,
  selectPresentQuery,
  selectHubConnectionId,
} from 'common/selectors';
import * as actions from '../../redux/actions';
import { ZoomType } from '../types';
import { ViewerControl, MouseTool, Mark } from '@prizmdoc/viewer-core';
import AcusoftViewerToolbox from './AcusoftViewerToolbox';
import {
  getScrollContainer,
  makeMarkDisappear,
  setScroll,
  setSearchFunction,
  setMouse,
  zoom,
  getDocumentRotationInfo,
  createAnnotations,
  setOverflowHidden,
} from './utils';
import { openNewBackgroundTab } from 'utils/browser';
import { PRESENT_COLOR, MARK_TYPE } from './constants';
import { useSelector } from 'react-redux';
import { useSendMessages } from './hooks/useSendMessages';
import { useScrollToHighlight } from './hooks/useScrollToHighlight';
import { usePauseDocument } from './hooks/usePauseDocument';
import { usePerfectScrollbarUpdater } from './hooks/usePerfectScrollbarUpdater';
import { useFetchAnnotations } from 'features/viewing/redux/fetchAnnotations';
import NotionInterface from './NotionInterface';
import { SidebarViewer } from 'features/viewing';
import { useClearAnnotations } from 'features/viewing/redux/clearAnnotations';
import { useMarks } from './hooks/useMarks';
import debounce from 'utils/debounce';
import { diff } from 'deep-object-diff';
import { isEmpty } from 'utils/objects';
import { useDispatch } from 'react-redux';
import logger from 'utils/logger';
import SideSummaryDisplay from './SideSummaryDisplay';
import { makeCancelable } from 'utils/promises';
import Split from 'react-split';
import classNames from 'classnames';
import Logger from 'utils/logger';

const AcusoftViewerContainer = ({
  removeInput,
  enableScroll,
  className,
  options,
  viewerReadyHandler,
  pageOpeningHandler,
  pageDisplayedHandler,
  pageListElementRef,
  viewerControlRef,
}: any) => {
  return (
    <div
      // ACUSOFT CONTAINER
      style={{
        ...(removeInput && !enableScroll && { pointerEvents: 'none' }),
      }}
      data-pcc-pagelist
      className={className}
      ref={el => {
        if (el !== null && !pageListElementRef.current) {
          pageListElementRef.current = el;

          viewerControlRef.current = new ViewerControl(el, options);
          viewerControlRef.current.on('ViewerReady', viewerReadyHandler);
          viewerControlRef.current.on('PageOpening', pageOpeningHandler);
          viewerControlRef.current.on('PageDisplayed', pageDisplayedHandler);
        }
      }}
    />
  );
};

export default memo(
  forwardRef(
    (
      {
        fileId,
        removeInput,
        fileData,
        className,
        enableScroll,
        isFullScreen,
        isPreviewMode,
        options,
        viewingFilePrivate,
        passViewingSessionIdToReceivers,
        firstParentTriaBundleFileDetails,
        hearingRoomMode,
        searchHandler,
        searchFunctionsHandler,
        sideSummaryOpen,
        setSideSummaryOpen,
        fileHasSummary,
        fetchViewingSessionIdHandler,
      }: any,
      ref,
    ) => {
      const fetchTheatreDataPending = useSelector(
        (state: any) => state.viewing.fetchTheatreDataPending,
      );
      const presentQuery = useSelector(selectPresentQuery);
      const annotationId = useSelector((state: any) => state.matcher.params.annotationId);
      const prevAnnotationIdRef = useRef(null);
      const connectionId = useSelector(selectHubConnectionId);
      const userId = useSelector(selectUserId);
      const permissions = useSelector(selectPermissions);
      const currentCase = useSelector(selectCurrentCase);
      const filteredAnnotations = useSelector(selectFilteredDocumentAnnotations);
      const isLiveStream = useSelector(selectIsLiveStream);
      const currentFolder = useSelector(selectCurrentFolder);
      const currentFileMetaData = useSelector(selectCurrentSelectedFileMetaData) as any;
      const hearingRoomStarted = useSelector(selectSessionStarted);
      const prevHearingRoomStartedRef = useRef(null);
      const isGlobalPageLookup = useSelector(selectIsGlobalPageLookup);
      const queryParam = useSelector(selectQuery);
      const currentUrl = useSelector(selectMatchedUrl);
      const theatreData = useSelector((state: any) =>
        selectTheatreData(state, hearingRoomMode),
      ) as any;
      const markBasedScrolling = useSelector(selectMarkBasedScrolling);

      const presenterConnectionId = useMemo(() => {
        return (
          (fileId &&
            (theatreData?.messages || []).find(
              (message: any) => message.fileId === fileId && userId === message.presenterId,
            )?.connectionId) ||
          null
        );
      }, [fileId, theatreData?.messages, userId]);
      const hasBeenPresenting = useRef(false);
      const prevPresenterConnectionIdRef = useRef(null);
      const showGlobalPaging = currentFolder && currentFolder.globalPaging;
      const isPresenting = !!presentQuery;
      const allHighlights = useMemo(
        () => (isPreviewMode || isPresenting ? [] : filteredAnnotations),
        [isPreviewMode, isPresenting, filteredAnnotations],
      );
      const prevHightlightsRef = useRef<any>([]);
      const isPresentModePage = currentUrl.includes(`${currentCase.id}/present-`);
      const isTheatreMode = !!hearingRoomMode;
      const showSidebar = !isTheatreMode && isFullScreen && !isPreviewMode;
      const [isViewerReady, setIsViewerReady] = useState<false | undefined | true | null>(
        undefined,
      );

      const [selectedMouseTool, setSelectedMouseTool] = useState(MouseTool.Type.PanAndEdit);
      const [marksCreatedFirstTime, setMarksCreatedFirstTime] = useState(false);

      const [, updateState] = React.useState<any>();

      const forceUpdate = React.useCallback(() => updateState({}), []);

      const [hasClickedSummary, setHasClickedSummary] = useState(false);
      const pageListElementRef = useRef<any>(null);
      const viewerControlRef = useRef<any>();
      const initializingRef = useRef<number>(0);
      const hideTimeoutRef = useRef<any>(null);
      const prevDoubleTimestampRef = useRef<number>(0);
      const prevTrippleTimestampRef = useRef<number>(0);
      const toolboxRef = useRef<any>(null);

      const { fetchAnnotations } = useFetchAnnotations();
      const { clearAnnotations } = useClearAnnotations();

      const updatePerfectScrollbar = usePerfectScrollbarUpdater(viewerControlRef);

      const pauseCreateMarkHandlerRef = useRef<any>(null);
      const pauseCreateMarkHandlerRef2 = useRef<any>(null);

      const {
        unpauseTheatreModeHandler,
        disconnectPauseTheatreModeHandler,
        pauseTheatreMode,
      } = usePauseDocument(viewerControlRef, isTheatreMode, initializingRef, selectedMouseTool);
      const {
        deleteMarkToBeCreated,
        setMarkToBeUpdated,
        markToBeCreated,
        markToBeUpdated,
        setMarkToBeCreated,
      } = useMarks(
        {
          viewerControl: viewerControlRef.current,
          isPresenting,
          isTheatreMode,
        },
        pauseCreateMarkHandlerRef,
        pauseCreateMarkHandlerRef2,
      );

      const setCurrentMouseTool = useCallback(
        (selMouseTool, isPresent?) => {
          deleteMarkToBeCreated();
          if (viewerControlRef.current)
            setMouse(
              selMouseTool,
              isPresent !== undefined ? isPresent : isPresenting,
              viewerControlRef.current,
            );
        },
        [deleteMarkToBeCreated, isPresenting],
      );

      const { startStopPresentHandler, updateScrollPositionForReceivers } = useSendMessages({
        passViewingSessionIdToReceivers,
        firstParentTriaBundleFileDetails,
        fileId,
        options,
        viewingFilePrivate,
        viewerControlRef: viewerControlRef,
        setCurrentMouseTool,
        selectedMouseTool,
        deleteMarkToBeCreated,
      });

      const { scrollSelectedHighlight, scrollToHighlight } = useScrollToHighlight({
        viewerControl: viewerControlRef.current,
      });

      const viewerControlMouseDownHandler = useCallback(
        (e: any) => {
          deleteMarkToBeCreated();
        },
        [deleteMarkToBeCreated],
      );

      const viewerControlClickedHandler = useCallback(
        (e: any) => {
          if (e.originalEvent.timeStamp - prevTrippleTimestampRef.current < 250) {
            prevDoubleTimestampRef.current = 0;
            prevTrippleTimestampRef.current = 0;
          } else if (e.originalEvent.timeStamp - prevDoubleTimestampRef.current < 250) {
            prevDoubleTimestampRef.current = 0;
            prevTrippleTimestampRef.current = e.originalEvent.timeStamp;
          } else {
            prevDoubleTimestampRef.current = e.originalEvent.timeStamp;
          }

          if (e.mark && viewerControlRef.current.getAllMarks().includes(e.mark)) {
            if (e.originalEvent.button === 2) {
            } else {
              if (e.mark.uid === markToBeCreated?.uid) return;
              if (e.mark.type === Mark.Type.TextHyperlinkAnnotation) {
                openNewBackgroundTab(e.mark.href);
              } else {
                setMarkToBeUpdated(e.mark);
              }
            }
          }
        },
        [markToBeCreated?.uid, setMarkToBeUpdated],
      );

      const markSelectionChangedHandler = useCallback(
        (e: any) => {
          const allSelectedHighlights = viewerControlRef.current.getSelectedMarks();

          // prevent multiselect for highlights
          if (allSelectedHighlights.length > 1) {
            viewerControlRef.current.deselectMarks([allSelectedHighlights[0]]);
          } else if (allSelectedHighlights.length === 0) {
            setMarkToBeUpdated(undefined);
          } else {
            setMarkToBeUpdated(allSelectedHighlights[0]);
          }
        },
        [setMarkToBeUpdated],
      );

      const setAllTheatreViewerData = useCallback(() => {
        const { scroll, scaleFactor, documentRotation, markObjects = [] } = fileData;

        const rotate = documentRotation - viewerControlRef.current.getPageRotation();

        const decreseIntitializing = () => {
          setTimeout(() => {
            --initializingRef.current;
            if (initializingRef.current === 0) {
              forceUpdate();
            }
          }, 500);
        };

        const setScaleFactor = () => {
          viewerControlRef.current.getScaleFactor() !== scaleFactor &&
            viewerControlRef.current.setScaleFactor(
              Math.max(
                Math.min(viewerControlRef.current.getMaxScaleFactor(), scaleFactor),
                viewerControlRef.current.getMinScaleFactor(),
              ),
            );
          decreseIntitializing();
        };

        const setRotation = () => {
          if (rotate) {
            const onRotation = () => {
              decreseIntitializing();
              viewerControlRef.current.off('DocumentRotated', onRotation);
            };
            viewerControlRef.current.on('DocumentRotated', onRotation);
            viewerControlRef.current.rotateDocument(rotate);
          } else {
            decreseIntitializing();
          }
        };

        const _setScroll = () => {
          if (rotate) {
            if (!markBasedScrolling) {
              const centerElementX = getScrollContainer(viewerControlRef.current).clientWidth / 2;
              const centerElementY = getScrollContainer(viewerControlRef.current).clientHeight / 2;
              getScrollContainer(viewerControlRef.current).scrollTo(
                scroll.X * getScrollContainer(viewerControlRef.current).scrollWidth -
                  centerElementX,
                scroll.Y * getScrollContainer(viewerControlRef.current).scrollHeight -
                  centerElementY,
              );
            }

            decreseIntitializing();
          } else {
            if (!markBasedScrolling) {
              setScroll(getScrollContainer(viewerControlRef.current), scroll.X, scroll.Y).then(
                () => {
                  decreseIntitializing();
                },
              );
            } else {
              decreseIntitializing();
            }
          }
        };

        const applyHighlights = () => {
          try {
            const existingMarks = viewerControlRef.current.getAllMarks();

            const markObjectsToDeleteUids = markObjects
              .filter(
                ({ uid, ...rest }: any) =>
                  Object.keys(rest).length === 0 || rest?.data?.visible === 'false',
              )
              .map((x: any) => x.uid);
            const markObjectsToDelete = existingMarks.filter(({ uid }: any) =>
              markObjectsToDeleteUids.includes(uid),
            );
            // delete some marks
            if (markObjectsToDelete && markObjectsToDelete.length > 0) {
              viewerControlRef.current.deleteMarks(markObjectsToDelete);
            }

            const existingMarksUids = existingMarks.map((x: any) => x.uid);
            const newMarksObjects = markObjects.filter(
              ({ uid, ...rest }: any) =>
                Object.keys(rest).length > 0 && !existingMarksUids.includes(uid),
            );

            // create some new marks
            const newMarks =
              newMarksObjects.length > 0 &&
              viewerControlRef.current.deserializeMarks(newMarksObjects);

            const onlyPresentMarks = newMarks
              ? newMarks.filter((mark: any) => mark.type !== MARK_TYPE)
              : [];
            const scrollMarks = newMarks
              ? newMarks.filter((mark: any) => mark.type === MARK_TYPE)
              : [];

            // last one needs not to disappear
            if (onlyPresentMarks.length > 0) {
              existingMarks &&
                existingMarks.forEach((mark: any) => {
                  if (
                    mark.fillColor === PRESENT_COLOR &&
                    mark.interactionMode === Mark.InteractionMode.SelectionDisabled &&
                    ((mark.opacity && mark.opacity === 150) ||
                      (typeof mark.opacity === 'undefined' && getAlpha(mark.fillColor) === 255))
                  ) {
                    makeMarkDisappear(mark);
                  }
                });

              onlyPresentMarks
                .slice(0, -1)
                .map(
                  (mark: any) =>
                    mark.interactionMode === Mark.InteractionMode.SelectionDisabled &&
                    makeMarkDisappear(mark),
                );
            }

            if (scrollMarks.length > 0) {
              new Promise<void>(r => {
                if (scrollMarks.length > 0) {
                  try {
                    viewerControlRef.current
                      .scrollToAsync(scrollMarks[scrollMarks.length - 1])
                      .then(() => {
                        viewerControlRef.current.deleteMarks([scrollMarks[scrollMarks.length - 1]]);
                        r();
                      });
                  } finally {
                    r();
                  }
                } else {
                  r();
                }
              }).then(() => {
                decreseIntitializing();
              });
            } else {
              decreseIntitializing();
            }
          } catch (e) {
            logger.ERROR(e);
            decreseIntitializing();
          }
        };

        initializingRef.current += removeInput ? 3 : 4; //it needs to reach 0 when all is completed
        !removeInput && setScaleFactor();
        setRotation();
        _setScroll();
        applyHighlights();
      }, [fileData, forceUpdate, markBasedScrolling, removeInput]);

      const updateScroll = debounce(() => updateScrollPositionForReceivers(), 500);

      const dontUseScrollHandlerRef = useRef(false);

      const scrollHandler = useCallback(() => {
        if (dontUseScrollHandlerRef.current) return;

        updateScroll();
      }, [updateScroll]);

      const scaleChangedHandler = useCallback(
        ({ scaleFactor }: any) => {
          try {
            setTimeout(() => {
              updateScrollPositionForReceivers({
                scaleFactor,
              });
              // this handler get's removed when changing scale
              dontUseScrollHandlerRef.current = false;
            }, 0);
          } catch (e) {
            logger.ERROR(e);
          }
        },
        [updateScrollPositionForReceivers],
      );

      const documentRotatedHandler = useCallback(() => {
        setTimeout(() => {
          updateScrollPositionForReceivers(getDocumentRotationInfo(viewerControlRef.current));
          // this handler get's removed when rotating document
          dontUseScrollHandlerRef.current = false;
        }, 0);
      }, [updateScrollPositionForReceivers]);

      const [highlights, setHighlights] = useState([]);
      const filterHighlightsCancelablePromise = useRef<any>(null);

      const handlePageLoadFailed = useCallback(
        (e: any) => {
          if (e.statusCode === 403 && e.errorMessage.contains('Expired viewer session ID')) {
            fetchViewingSessionIdHandler();
          }
          Logger.ERROR(e);
        },
        [fetchViewingSessionIdHandler],
      );

      const handlePageChangedAnnotations = debounce(
        useCallback(
          ({ pageNumber, reset }: any) => {
            filterHighlightsCancelablePromise.current?.cancel();
            filterHighlightsCancelablePromise.current = makeCancelable(
              new Promise(resolve => {
                const highlightsToShow = Array.from(
                  new Set([
                    ...(reset ? [] : highlights),
                    ...allHighlights.filter(({ annotation }: any) => {
                      return [Math.max(pageNumber - 1, 0), pageNumber, pageNumber + 1].includes(
                        annotation.pageNumber,
                      );
                    }),
                  ]),
                ) as any;
                resolve(highlightsToShow);
              }).then((highlightsToShow: any) => {
                if (highlightsToShow.length !== highlights.length) setHighlights(highlightsToShow);
              }),
            );
            filterHighlightsCancelablePromise.current.promise.then(() => {});

            toolboxRef.current?.pageNumberChangedHandler({
              pageNumber,
            });
          },
          [allHighlights, highlights],
        ),
        250,
      );

      useEffect(() => {
        if (!isTheatreMode)
          handlePageChangedAnnotations({
            pageNumber: viewerControlRef.current.pageNumber,
            reset: true,
          });
      }, [allHighlights]); // don't want highlights here, I've just set it to [] to trigger the effect

      const handlePageChanged = useCallback(({ pageNumber }: any) => {
        toolboxRef.current?.pageNumberChangedHandler({
          pageNumber,
        });
      }, []);

      const zoomHandler = useCallback((type: string) => {
        dontUseScrollHandlerRef.current = true;
        zoom(type, viewerControlRef.current);
      }, []);

      const rotateHandler = (angle: number) => {
        dontUseScrollHandlerRef.current = true;
        viewerControlRef.current.rotateDocument(angle);
      };

      const mouseToolHandler = useCallback(
        (toolType: any) => {
          setSelectedMouseTool(toolType);
          setCurrentMouseTool(toolType);
        },
        [setCurrentMouseTool],
      );

      const setPageNumberHandler = (pageNumber: number) => {
        const totalPageCount = viewerControlRef.current.pageCount;
        viewerControlRef.current.setPageNumber(pageNumber <= totalPageCount ? pageNumber : 1);
      };

      const createMarksInViewer = useCallback(
        (highlightsToWorkWith: any = highlights, differenceWithHighlights?: any) => {
          const currentMouseTool = selectedMouseTool;
          try {
            mouseToolHandler(MouseTool.Type.Pan);
            pauseCreateMarkHandlerRef2.current = true;

            createAnnotations(
              viewerControlRef.current,
              highlightsToWorkWith,
              userId,
              differenceWithHighlights,
            ).then((newMarks: any) => {
              // if (markToBeCreated) {
              //   setMarkToBeCreated(newMarks.find(({ uid }: any) => uid === markToBeCreated.uid));
              // }

              if (markToBeUpdated) {
                setMarkToBeUpdated(newMarks.find(({ uid }: any) => uid === markToBeUpdated.uid));
              }

              setTimeout(() => {
                pauseCreateMarkHandlerRef2.current = false;
                mouseToolHandler(currentMouseTool);
                setMarksCreatedFirstTime(true);
              }, 300); // needed for acusoft to stop triggering events
            });
          } catch (e) {
            logger.ERROR(e);
            pauseCreateMarkHandlerRef2.current = false;
            mouseToolHandler(currentMouseTool);
          }
        },
        [
          highlights,
          markToBeUpdated,
          mouseToolHandler,
          selectedMouseTool,
          setMarkToBeUpdated,
          userId,
        ],
      );

      const setReadyWithTimeout = useCallback(
        () =>
          setTimeout(() => {
            setIsViewerReady((prevState: any) => {
              let newState;
              // 4 stage to make it ready
              if (typeof prevState === 'undefined') {
                newState = null;
              } else if (prevState === null) {
                newState = false;
              } else {
                newState = true;
              }
              if (newState) {
                if (presentQuery) {
                  //start presenting if url is present and it's not presenting
                  startStopPresentHandler(presentQuery, true);
                }

                setSearchFunction(viewerControlRef.current, searchHandler, searchFunctionsHandler);
              }

              return newState;
            });
          }),
        [presentQuery, searchFunctionsHandler, searchHandler, startStopPresentHandler],
      );

      const pageOpeningHandler = () => {
        if (!isTheatreMode) {
          // make document go out a bit, no default zoom
          scaleChangedHandler(ZoomType.Out);
        }

        viewerControlRef.current?.off('PageOpening', pageOpeningHandler);
      };

      const pageDisplayedHandler = () => {
        setReadyWithTimeout();

        viewerControlRef.current?.off('PageDisplayed', pageDisplayedHandler);
      };

      const viewerReadyHandler = () => {
        setReadyWithTimeout();
        setOverflowHidden();

        viewerControlRef.current.off('ViewerReady', viewerReadyHandler);
      };

      const pageCountReadyHandler = useCallback(() => {
        updatePerfectScrollbar();
        setTimeout(() => {
          try {
            handlePageChanged({ pageNumber: viewerControlRef.current.pageNumber });
          } catch (e) {
            // TO-DO HIDING ERROR due to timeout set
          }
        }, 250);

        let count = 0;
        const countAllOfThem = () => {
          ++count;
          if (count === highlights.length || isTheatreMode) {
            viewerControlRef.current.off('MarkCreated', countAllOfThem);
            setTimeout(() => {
              setReadyWithTimeout();
            });
          }
        };
        viewerControlRef.current.on('MarkCreated', countAllOfThem);
        if (highlights.length > 0) {
          prevHightlightsRef.current = highlights;
          createMarksInViewer();
        } else {
          --count;
          countAllOfThem();
        }
      }, [
        createMarksInViewer,
        handlePageChanged,
        highlights,
        isTheatreMode,
        setReadyWithTimeout,
        updatePerfectScrollbar,
      ]);

      const dispatch = useDispatch();

      useEffect(() => {
        hasBeenPresenting.current = hasBeenPresenting.current || isPresenting;
      }, [isPresenting]);

      // mount
      useEffect(() => {
        if (!isPreviewMode) {
          fetchAnnotations({
            file: fileId,
            hearingRoomMode,
          });
        }

        const vr = viewerControlRef.current;

        // Unmount
        return () => {
          const newUrlQuery =
            qs.parse(window.location.search && window.location.search.substr(1)) || {};

          if (hasBeenPresenting.current && !newUrlQuery.present) {
            dispatch(actions.leaveAGroup('present'));
          }
          clearAnnotations();
          vr?.destroy();
        };
        // MOUNT
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      useEffect(() => {
        viewerControlRef.current.on('PageCountReady', pageCountReadyHandler);
        const vr = viewerControlRef.current;

        return () => {
          vr.off('PageCountReady', pageCountReadyHandler);
        };
      }, [pageCountReadyHandler]);

      useEffect(() => {
        if (!isViewerReady) return;

        const clearMarkToBeUpdated = () => {
          setMarkToBeUpdated(undefined);
        };

        // annotations and highlights
        //added to check vivercontrol is cicked or not
        viewerControlRef.current.on('MarkSelectionChanged', markSelectionChangedHandler);
        viewerControlRef.current.on('Click', viewerControlClickedHandler);
        viewerControlRef.current.on('MouseDown', viewerControlMouseDownHandler);
        getScrollContainer(viewerControlRef.current).addEventListener(
          'mousedown',
          (deleteMarkToBeCreated as unknown) as any,
        );
        getScrollContainer(viewerControlRef.current).addEventListener(
          'mousedown',
          clearMarkToBeUpdated,
        );
        if (!isTheatreMode) {
          // document
          getScrollContainer(viewerControlRef.current).addEventListener('scroll', scrollHandler);
          viewerControlRef.current.on('ScaleChanged', scaleChangedHandler);
          viewerControlRef.current.on('DocumentRotated', documentRotatedHandler);
          viewerControlRef.current.on('PageChanged', handlePageChangedAnnotations);
        }
        // when page or scale changes we need to update customToolbox
        viewerControlRef.current.on('PageChanged', handlePageChanged);
        viewerControlRef.current.on('PageLoadFailed', handlePageLoadFailed);

        const vr = viewerControlRef.current;

        return () => {
          vr?.off('MarkSelectionChanged', markSelectionChangedHandler);
          vr?.off('Click', viewerControlClickedHandler);
          vr?.off('MouseDown', viewerControlMouseDownHandler);
          getScrollContainer(vr)?.removeEventListener(
            'mousedown',
            (deleteMarkToBeCreated as unknown) as any,
          );
          getScrollContainer(vr)?.removeEventListener('mousedown', clearMarkToBeUpdated);
          if (!isTheatreMode) {
            // document
            getScrollContainer(vr)?.removeEventListener('scroll', scrollHandler);
            vr?.off('ScaleChanged', scaleChangedHandler);
            vr?.off('DocumentRotated', documentRotatedHandler);
            vr?.off('PageChanged', handlePageChangedAnnotations);
          }
          // when page or scale changes we need to update customToolbox
          vr?.off('PageChanged', handlePageChanged);
          vr?.off('PageLoadFailed', handlePageLoadFailed);
        };
      }, [
        deleteMarkToBeCreated,
        documentRotatedHandler,
        handlePageChanged,
        handlePageChangedAnnotations,
        handlePageLoadFailed,
        isTheatreMode,
        isViewerReady,
        markSelectionChangedHandler,
        scaleChangedHandler,
        scrollHandler,
        setMarkToBeUpdated,
        viewerControlClickedHandler,
        viewerControlMouseDownHandler,
      ]);

      useEffect(() => {
        if (
          isViewerReady &&
          isTheatreMode &&
          fileData &&
          !pauseTheatreMode &&
          !initializingRef.current
        ) {
          setAllTheatreViewerData();
        }
      }, [fileData, isTheatreMode, isViewerReady, pauseTheatreMode, setAllTheatreViewerData]);

      useEffect(() => {
        if (isViewerReady && !isTheatreMode) {
          if (
            annotationId &&
            prevAnnotationIdRef.current !== annotationId &&
            allHighlights?.length > 0
          ) {
            prevAnnotationIdRef.current = annotationId;
            const annotation = allHighlights.find(
              (highlight: any) => highlight.id === annotationId,
            );

            if (annotation) {
              scrollToHighlight(annotation);
            }
          }
        }
      }, [
        allHighlights,
        isTheatreMode,
        isViewerReady,
        scrollToHighlight,
        annotationId,
        marksCreatedFirstTime,
      ]);

      useEffect(() => {
        if (!isViewerReady) return;

        const highlightIds = highlights.map(({ id }: any) => id);
        const highlightsDiffMap = [] as any;
        const highlightsNewMap = [] as any;

        prevHightlightsRef.current.forEach((oldHighlight: any, index: any) => {
          const idIndex = highlightIds.indexOf(oldHighlight.id);
          if (idIndex > -1) {
            highlightIds[idIndex] = null;
            highlightsDiffMap[index] = highlights[idIndex];
            highlightsNewMap[index] = highlights[idIndex];
          } else {
            highlightsDiffMap[index] = undefined;
            highlightsNewMap[index] = oldHighlight;
          }
        });

        highlightIds.filter(Boolean).forEach((id: any) => {
          const idIndex = highlightIds.indexOf(id);
          if (idIndex > -1) {
            highlightsDiffMap[highlightsDiffMap.length] = highlights[idIndex];
            highlightsNewMap[highlightsNewMap.length] = highlights[idIndex];
          }
        });

        const difference = diff(prevHightlightsRef.current, highlightsDiffMap);

        if (!isEmpty(difference)) {
          createMarksInViewer(highlightsNewMap, difference);
          prevHightlightsRef.current = highlights;
        }
      }, [createMarksInViewer, highlights, isViewerReady]);

      useEffect(() => {
        // in case someone else presents
        if (
          isViewerReady &&
          !fetchTheatreDataPending &&
          prevPresenterConnectionIdRef.current !== presenterConnectionId
        ) {
          const shouldIBePresenting = presenterConnectionId === connectionId;
          if (prevPresenterConnectionIdRef.current && isPresenting && !shouldIBePresenting) {
            startStopPresentHandler(presentQuery);
          }
          prevPresenterConnectionIdRef.current = presenterConnectionId;
        }
      }, [
        connectionId,
        fetchTheatreDataPending,
        isPresenting,
        isViewerReady,
        presentQuery,
        presenterConnectionId,
        startStopPresentHandler,
      ]);

      useEffect(() => {
        // in case hearing room is stopped
        if (
          presentQuery === 'public' &&
          prevHearingRoomStartedRef.current !== hearingRoomStarted &&
          !hearingRoomStarted
        ) {
          if (
            ['paused', 'started'].includes(
              (prevHearingRoomStartedRef.current as unknown) as string,
            ) &&
            hearingRoomStarted === 'stopped'
          ) {
            startStopPresentHandler();
          }
          prevHearingRoomStartedRef.current = hearingRoomStarted;
        }
      }, [
        connectionId,
        fetchTheatreDataPending,
        hearingRoomStarted,
        isViewerReady,
        startStopPresentHandler,
        presenterConnectionId,
        presentQuery,
      ]);

      useImperativeHandle(ref, () => ({
        stopPresent: () => {
          if (presentQuery) {
            startStopPresentHandler();
          }
        },
      }));

      useEffect(() => {
        if (isViewerReady && !isTheatreMode) {
          if (sideSummaryOpen && !hasClickedSummary) {
            zoomHandler(ZoomType.Summary);
            setHasClickedSummary(true);
          }
        }
      }, [hasClickedSummary, isTheatreMode, isViewerReady, sideSummaryOpen, zoomHandler]);

      const shouldShowGlobalPaging =
        showGlobalPaging ||
        (fileData && fileData.showGlobalPaging) ||
        Object.keys(queryParam).includes('startPage') ||
        isGlobalPageLookup ||
        (firstParentTriaBundleFileDetails &&
          Object.keys(firstParentTriaBundleFileDetails).length > 0) ||
        (isPresentModePage &&
          currentFileMetaData &&
          Object.keys(currentFileMetaData).includes('folderId'));

      const goToFirstPage = () => viewerControlRef.current.changeToFirstPage();
      const goToLastPage = () => viewerControlRef.current.changeToLastPage();
      const goToPrevPage = () => viewerControlRef.current.changeToPrevPage();
      const goToNextPage = () => viewerControlRef.current.changeToNextPage();

      return (
        <Split
          sizes={showSidebar ? [80, 20] : [100, 0]}
          minSize={showSidebar ? 100 : 0}
          expandToMin={false}
          gutterSize={10}
          gutterAlign="center"
          snapOffset={30}
          dragInterval={1}
          direction="horizontal"
          cursor="col-resize"
          style={{
            height: '100%',
            width: sideSummaryOpen ? 'calc(100% - 20vw)' : '100%',
            display: 'flex',
          }}
          className={classNames({ 'no-gutter': !showSidebar })}
        >
          <div
            id="div22"
            style={{
              // width: '100%',
              height: '100%',
              position: 'relative',
              flex: 3,
              overflow: 'auto',
            }}
          >
            {!removeInput && isViewerReady && !isLiveStream && pageListElementRef.current && (
              <AcusoftViewerToolbox
                ref={toolboxRef}
                viewerContainer={pageListElementRef.current}
                isTheatreMode={isTheatreMode}
                presenter={fileData?.presenter}
                fileId={fileId}
                pauseTheatreMode={pauseTheatreMode}
                presentMode={presentQuery}
                currentScaleFactor={viewerControlRef.current.getScaleFactor()}
                pageCount={viewerControlRef.current.pageCount}
                zoom={zoomHandler}
                rotate={rotateHandler}
                unpauseTheatreMode={unpauseTheatreModeHandler}
                disconnectPauseTheatreMode={disconnectPauseTheatreModeHandler}
                mouseTool={mouseToolHandler}
                selectedMouseTool={selectedMouseTool}
                goToFirstPage={goToFirstPage}
                goToLastPage={goToLastPage}
                goToPrevPage={goToPrevPage}
                goToNextPage={goToNextPage}
                setPageNumber={setPageNumberHandler}
                present={startStopPresentHandler}
                permissions={permissions}
                privateFile={viewingFilePrivate}
                showGlobalPaging={shouldShowGlobalPaging}
                currentFileMetaData={
                  currentFileMetaData &&
                  Object.keys(currentFileMetaData).length > 0 &&
                  !Object.keys(queryParam).includes('startPage')
                    ? {
                        ...currentFileMetaData,
                        ...(firstParentTriaBundleFileDetails &&
                          Object.keys(firstParentTriaBundleFileDetails).length > 0 &&
                          firstParentTriaBundleFileDetails),
                      }
                    : fileData
                    ? fileData.currentFileDetails
                    : Object.keys(queryParam).includes('startPage')
                    ? { id: fileId, ...queryParam }
                    : null
                }
                isPresentModePage={isPresentModePage}
                isFullScreen={isFullScreen}
                isPreviewMode={isPreviewMode}
                userId={userId}
              />
            )}
            <AcusoftViewerContainer
              removeInput={removeInput}
              enableScroll={enableScroll}
              className={className}
              options={options}
              viewerReadyHandler={viewerReadyHandler}
              pageOpeningHandler={pageOpeningHandler}
              pageDisplayedHandler={pageDisplayedHandler}
              pageListElementRef={pageListElementRef}
              viewerControlRef={viewerControlRef}
            />
            {isViewerReady && !isPresenting && (
              <NotionInterface
                viewerControl={viewerControlRef.current}
                isPresenting={isPresenting}
                markToBeCreated={markToBeCreated}
                markToBeUpdated={markToBeUpdated}
                highlights={highlights}
                isTheatreMode={isTheatreMode}
                fileId={fileId}
                deleteMarkToBeCreated={deleteMarkToBeCreated}
                setMarkToBeUpdated={setMarkToBeUpdated}
                setMarkToBeCreated={setMarkToBeCreated}
                hideTimeoutRef={hideTimeoutRef}
                pauseCreateMarkHandlerRef={pauseCreateMarkHandlerRef}
                userId={userId}
              />
            )}
          </div>
          <div>
            {showSidebar && (
              <SidebarViewer
                selectedHighlight={scrollSelectedHighlight}
                highlights={allHighlights}
                scrollToHighlight={scrollToHighlight}
                userId={userId}
                fileId={fileId}
                fileName={fileData?.fileName}
                fileHasSummary={fileHasSummary}
              />
            )}
            {sideSummaryOpen && (
              <SideSummaryDisplay
                open={sideSummaryOpen}
                fileId={fileId}
                fileName={fileData?.fileName}
                setLocalSidebarOpen={setSideSummaryOpen}
                fileHasSummary={fileHasSummary}
              />
            )}
          </div>
        </Split>
      );
    },
  ),
);
