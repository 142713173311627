import {
  CASE_PARSE_MATCH_BEGIN,
  CASE_PARSE_MATCH_SUCCESS,
  CASE_PARSE_MATCH_FAILURE,
  CASE_PARSE_MATCH_DISMISS_FEEDBACK,
} from './constants';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId } from '../../../common/selectors';

export const parseMatch = createPromiseAction(CASE_PARSE_MATCH_BEGIN);

export function dismissParseMatchFeedback() {
  return {
    type: CASE_PARSE_MATCH_DISMISS_FEEDBACK,
  };
}

export function* doParseMatch(action) {
  const { caseId, fileId, text, keyPhrase } = action.payload;

  let res = yield call(api.post, `/cases/${caseId}/files/${fileId}/parse-match`, {
    text,
    keyPhrase,
  });

  if (res && res.error) {
    yield put({
      type: CASE_PARSE_MATCH_FAILURE,
      feedback: {
        message: 'feedback.parseMatchFailure',
        error: res.error,
        retryAction: action,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: CASE_PARSE_MATCH_SUCCESS,
    data: { res },
  });
  yield call(resolvePromiseAction, action, res);
}

export function* watchParseMatch() {
  yield takeEvery(parseMatch, withCurrentCaseId(doParseMatch));
}

export function reducer(state, action) {
  switch (action.type) {
    case CASE_PARSE_MATCH_BEGIN + '.TRIGGER':
      // Just after a request is sent
      return {
        ...state,
        parseMatchPending: true,
        parseMatchFeedback: null,
      };

    case CASE_PARSE_MATCH_SUCCESS:
      // The request is success
      return {
        ...state,
        parseMatchPending: false,
        parseMatchFeedback: action.feedback,
      };

    case CASE_PARSE_MATCH_FAILURE:
      // The request is failed
      return {
        ...state,
        parseMatchPending: false,
        parseMatchFeedback: action.feedback,
      };

    case CASE_PARSE_MATCH_DISMISS_FEEDBACK:
      // Dismiss the request failure error
      return {
        ...state,
        parseMatchFeedback: null,
      };

    default:
      return state;
  }
}
