import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  CASE_GET_MORE_INFO_DOCUMENT_BEGIN,
  CASE_GET_MORE_INFO_DOCUMENT_SUCCESS,
  CASE_GET_MORE_INFO_DOCUMENT_FAILURE,
  CASE_GET_MORE_INFO_DOCUMENT_DISMISS_FEEDBACK,
} from './constants';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId } from '../../../common/selectors';

export const getMoreInfoDocument = createPromiseAction(CASE_GET_MORE_INFO_DOCUMENT_BEGIN);

export function dismissGetMoreInfoDocumentFeedback() {
  return {
    type: CASE_GET_MORE_INFO_DOCUMENT_DISMISS_FEEDBACK,
  };
}

export function* doGetMoreInfoDocument(action) {
  const { caseId, documentId, pipelineLog } = action.payload;

  let res = yield call(
    api.get,
    `/cases/${caseId}/files/${documentId}/logs${pipelineLog ? '?pipeline=true' : ''}`,
  );

  if (res && res.error) {
    yield put({
      type: CASE_GET_MORE_INFO_DOCUMENT_FAILURE,
      feedback: {
        message: 'feedback.getMoreInfoDocumentFailure',
        error: res.error,
        retryAction: action,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: CASE_GET_MORE_INFO_DOCUMENT_SUCCESS,
    data: { moreInfoDocument: res },
  });
  yield call(resolvePromiseAction, action, res);
}

export function* watchGetMoreInfoDocument() {
  yield takeEvery(getMoreInfoDocument, withCurrentCaseId(doGetMoreInfoDocument));
}

export function useGetMoreInfoDocument() {
  const dispatch = useDispatch();

  const { moreInfoDocument, getMoreInfoDocumentPending, getMoreInfoDocumentFeedback } = useSelector(
    state => ({
      moreInfoDocument: state.case.moreInfoDocument,
      getMoreInfoDocumentPending: state.case.getMoreInfoDocumentPending,
      getMoreInfoDocumentFeedback: state.case.getMoreInfoDocumentFeedback,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(getMoreInfoDocument(...args));
    },
    [dispatch],
  );

  const boundDismissFeedback = useCallback(() => {
    return dispatch(dismissGetMoreInfoDocumentFeedback());
  }, [dispatch]);

  return {
    moreInfoDocument,
    getMoreInfoDocument: boundAction,
    getMoreInfoDocumentPending,
    getMoreInfoDocumentFeedback,
    dismissGetMoreInfoDocumentFeedback: boundDismissFeedback,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case CASE_GET_MORE_INFO_DOCUMENT_BEGIN + '.TRIGGER':
      // Just after a request is sent
      return {
        ...state,
        getMoreInfoDocumentPending: true,
        getMoreInfoDocumentFeedback: null,
      };

    case CASE_GET_MORE_INFO_DOCUMENT_SUCCESS:
      // The request is success
      return {
        ...state,
        getMoreInfoDocumentPending: false,
        getMoreInfoDocumentFeedback: action.feedback,
        moreInfoDocument: action.data.moreInfoDocument,
      };

    case CASE_GET_MORE_INFO_DOCUMENT_FAILURE:
      // The request is failed
      return {
        ...state,
        getMoreInfoDocumentPending: false,
        getMoreInfoDocumentFeedback: action.feedback,
      };

    case CASE_GET_MORE_INFO_DOCUMENT_DISMISS_FEEDBACK:
      // Dismiss the request failure Feedback
      return {
        ...state,
        getMoreInfoDocumentFeedback: null,
      };

    default:
      return state;
  }
}
