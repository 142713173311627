import React, { useState } from 'react';
import T from 'i18n';
import {
  Button,
  Divider,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
} from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { AccessTime, Close, Search } from '@mui/icons-material';
import { useFetchSearchedFiles } from 'features/case/redux/hooks';
import { useUpdateUserSettings } from '../redux/updateUserSettings';
import SearchMenuInputField from './SearchMenuInputField';
import { selectCurrentCaseId } from 'common/selectors';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(() =>
  createStyles({
    searchFieldSection: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '0 0.5rem 0.5rem 0.5rem',
    },
    listItemHeading: {
      fontSize: '0.9rem',
      color: 'grey',
      marginBottom: '0.3rem',
      flexGrow: 1,
    },
    searchIcon: {
      marginRight: '0.5rem',
      marginLeft: '0.5rem',
      color: '#ced4da',
    },
    clearBtn: {
      color: 'rgb(10 10 10 / 90%)',
      '&:hover': {
        color: 'black',
      },
    },
    divider: {
      height: '1.5rem',
    },
    closeBtn: {
      '&:hover': {
        color: 'black',
      },
    },
    listItemContainer: {
      paddingLeft: '0.5rem',
      paddingRight: '0.5rem',
    },
  }),
);

interface SearchMenuProps {
  handleCloseSearchMenu: Function;
  doSearch: Function;
  searchVal: string;
  handleSearch: Function;
}

export default function SearchMenuHome({
  handleCloseSearchMenu,
  doSearch,
  searchVal,
  handleSearch,
}: SearchMenuProps) {
  const currentCaseId = useSelector(selectCurrentCaseId);
  const classes = useStyles();
  const { fetchDocumentsPending } = useFetchSearchedFiles();
  const { userSettings } = useUpdateUserSettings();
  const [searchInpVal, setSearchInpVal] = useState(searchVal);

  const noContentMsg = !searchInpVal && !userSettings.recentSearches;
  const showRecentSearches =
    !searchInpVal && userSettings.recentSearches && userSettings.recentSearches[currentCaseId];

  const handleSearchInputChanged = (val: string) => setSearchInpVal(val);

  const clearSearch = () => {
    setSearchInpVal('');
    handleSearch('');
  };

  return (
    <List
      dense
      subheader={
        <ListSubheader sx={{ padding: 0 }}>
          <div className={classes.searchFieldSection}>
            <Search fontSize="medium" className={classes.searchIcon} />
            <SearchMenuInputField
              value={searchInpVal}
              placeholder={T.translate('case.searchMenuHomePlaceholder')}
              onSubmit={() => {
                doSearch(searchInpVal);
                handleCloseSearchMenu();
              }}
              onChange={handleSearchInputChanged}
            />
            {searchInpVal && (
              <Button
                className={classes.clearBtn}
                variant="text"
                color="info"
                onClick={() => clearSearch()}
              >
                {T.translate('common.clear')}
              </Button>
            )}
            <Divider className={classes.divider} orientation="vertical" variant="middle" />
            <IconButton onClick={() => handleCloseSearchMenu()}>
              <Close className={classes.closeBtn} />
            </IconButton>
          </div>
          <Divider />
        </ListSubheader>
      }
    >
      {searchInpVal && (
        <ListItemButton
          className={classes.listItemContainer}
          onClick={() => {
            doSearch(searchInpVal);
            handleCloseSearchMenu();
          }}
        >
          {fetchDocumentsPending ? (
            <LinearProgress sx={{ width: '100%', m: '2rem' }} />
          ) : (
            <>
              <Search fontSize="medium" className={classes.searchIcon} />
              <ListItemText
                primary={searchInpVal}
                primaryTypographyProps={{ fontSize: '1rem', fontWeight: '600' }}
              />
            </>
          )}
        </ListItemButton>
      )}
      {noContentMsg && (
        <ListItemText
          className={classes.listItemContainer}
          sx={{ textAlign: 'center' }}
          primary={T.translate('case.searchMenuMsg')}
          primaryTypographyProps={{ fontSize: '1rem' }}
        />
      )}
      {showRecentSearches && (
        <>
          <ListItem className={classes.listItemHeading}>
            {T.translate('case.recentSearchesMsg')}
          </ListItem>
          {userSettings.recentSearches[currentCaseId].map((itm: string, idx: number) => (
            <ListItemButton
              key={idx}
              className={classes.listItemContainer}
              onClick={() => {
                handleSearchInputChanged(itm);
                doSearch(itm);
                handleCloseSearchMenu();
              }}
            >
              <AccessTime fontSize="medium" sx={{ mr: '0.5rem', ml: '0.5rem' }} />
              <ListItemText
                primary={itm}
                primaryTypographyProps={{ fontSize: '1rem', fontWeight: '600' }}
              />
            </ListItemButton>
          ))}
        </>
      )}
    </List>
  );
}
